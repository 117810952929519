import React from 'react';
import Sidebar from '../Sidebar';
import Author from './Author';
import Comments from './Comments';
import Content from './Content';
import Meta from './Meta';
import Tags from './Tags';
import styles from './Post.module.scss';

const Post = ({ post }) => {
    const {
        tags,
        title,
        date
    } = post.frontmatter;

    const { html } = post;
    const { tagSlugs } = post.fields;

    return (
        <div className={styles['post']}>
            <Sidebar/>

            <div className={styles['post__body']}>
                <div className={styles['post__inner']}>
                    <section className={styles['post__content']}>
                        <Content body={html} title={title}/>
                    </section>
                    <div className={styles['post__footer']}>
                        <Meta date={date}/> <Tags tags={tags} tagSlugs={tagSlugs}/> <Author/>
                    </div>
                    <div className={styles['post__comments']}>
                        <Comments postSlug={post.fields.slug} postTitle={post.frontmatter.title}/>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Post;
